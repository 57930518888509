import logo from './logo.svg';
import './App.css';
import {useState} from 'react';
import {cloneDeep} from 'lodash';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const test = {
    event: 'OpHetVeld',
    brand: 'Boem',
    model: 'Patat',
}

function App() {
    const [data, setData] = useState(test)


    const handleClick = () => {
        const event = cloneDeep(data);
        window.dataLayer.push(event);
        console.log(event)
    }


    function onChange(newValue) {
        setData(newValue);
    }

    return (
        <div className="">
            <h1>Google Tag manager Tester</h1>
            <p>GTM: GTM-TD7TX32</p>
            <p>Analytics: G-PLRP6ZPWWX</p>

            <button style={{marginBottom: 10}} onClick={() => {
                setData(test)
            }}>Optveld</button>

            <button style={{marginBottom: 10}} onClick={() => {
                setData({
                    event: 'vehicleClick',
                    reference: '9c9703a1-8000-4f0d-9c50-316f4a01b54b',
                    make: 'audi',
                    model: 'a4',
                })
            }}>VehicleClickEvent</button>




            <JSONInput
                id='a_unique_id'
                placeholder={data}
                locale={locale}
                height='550px'
                onChange={(data) => setData(data.jsObject)}
            />

            <button style={{marginTop: 10}} onClick={handleClick}>SEND</button>

        </div>
    );
}

export default App;
